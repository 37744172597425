"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormCollapsePanel = exports.FormCollapse = void 0;
exports.FormCollapse = {
    'zh-CN': {
        title: '折叠面板',
        addCollapsePanel: '添加面板',
        settings: {
            'x-component-props': {
                accordion: {
                    title: '手风琴模式',
                    tooltip: '启用后一次只能展开一个',
                },
            },
        },
    },
    'en-US': {
        title: 'Collapse',
        addCollapsePanel: 'Add Panel',
        settings: {
            'x-component-props': {
                accordion: {
                    title: 'Accordion',
                    tooltip: 'When enabled, only one can be expanded at a time',
                },
            },
        },
    },
};
exports.FormCollapsePanel = {
    'zh-CN': {
        title: '面板',
    },
    'en-US': {
        title: 'Panel',
    },
};
