"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeSelect = void 0;
exports.TreeSelect = {
    type: 'object',
    properties: {
        size: {
            type: 'string',
            enum: ['small', 'medium', 'large', null],
            'x-decorator': 'FormItem',
            'x-component': 'Select',
            'x-component-props': {
                defaultValue: 'medium',
            },
        },
        placeholder: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
        hasArrow: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-component-props': {
                defaultChecked: true,
            },
        },
        hasBorder: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-component-props': {
                defaultChecked: true,
            },
        },
        hasClear: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        autoWidth: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-component-props': {
                defaultChecked: true,
            },
        },
        showSearch: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        notFoundContent: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
        multiple: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        treeCheckable: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        treeCheckStrictly: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        treeCheckedStrategy: {
            type: 'string',
            enum: ['parent', 'child', 'all'],
            'x-decorator': 'FormItem',
            'x-component': 'Select',
            'x-component-props': {
                defaultValue: 'parent',
            },
        },
        treeDefaultExpandAll: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        followTrigger: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        useVirtual: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        immutable: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
    },
};
