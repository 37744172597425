"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Transfer = void 0;
exports.Transfer = {
    'zh-CN': {
        title: '穿梭框',
        settings: {
            'x-component-props': {
                id: '标识',
                mode: {
                    title: '模式',
                    dataSource: ['正常', '简单'],
                },
                leftDisabled: '禁用左侧面板',
                rightDisabled: '禁用右侧面板',
                filter: '过滤函数',
                searchPlaceholder: '搜索占位提示',
                titles: '左右面板标题',
                sortable: '拖拽排序',
                showCheckAll: '底部全选',
            },
        },
    },
    'en-US': {
        title: 'Transfer',
        settings: {
            'x-component-props': {
                id: 'ID',
                mode: {
                    title: 'Mode',
                    dataSource: ['Normal', 'Simple'],
                },
                leftDisabled: 'Disable Left Panel',
                rightDisabled: 'Disable Right Panel',
                filter: 'Filter Function',
                searchPlaceholder: 'Search placeholder tips',
                titles: 'Titles',
                sortable: 'Sortable',
                showCheckAll: 'Show Check All',
            },
        },
    },
};
