"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    'en-US': {
        SettingComponents: {
            ValueInput: {
                expression: 'Expression',
            },
            MonacoInput: {
                helpDocument: 'Help Documents',
            },
        },
    },
};
