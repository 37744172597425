"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Card = void 0;
exports.Card = {
    type: 'object',
    properties: {
        title: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
        subTitle: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
        showTitleBullet: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-component-props': {
                defaultChecked: true,
            },
        },
        showHeadDivider: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-component-props': {
                defaultChecked: true,
            },
        },
        contentHeight: {
            'x-decorator': 'FormItem',
            'x-component': 'SizeInput',
        },
        extra: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
    },
};
