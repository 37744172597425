"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    'zh-CN': {
        SettingComponents: {
            ValueInput: {
                expression: '表达式',
            },
            MonacoInput: {
                helpDocument: '帮助文档',
            },
        },
    },
};
