"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    base: 'vs',
    inherit: true,
    rules: [
        {
            foreground: 'c41a16',
            token: 'string',
        },
        {
            foreground: '1c00cf',
            token: 'constant.numeric',
        },
        {
            foreground: 'aa0d91',
            token: 'keyword',
        },
        {
            foreground: '000000',
            token: 'keyword.operator',
        },
        {
            foreground: 'aa0d91',
            token: 'constant.language',
        },
        {
            foreground: '990000',
            token: 'support.class.exception',
        },
        {
            foreground: '000000',
            token: 'entity.name.function',
        },
        {
            fontStyle: 'bold underline',
            token: 'entity.name.type',
        },
        {
            fontStyle: 'italic',
            token: 'variable.parameter',
        },
        {
            foreground: '007400',
            token: 'comment',
        },
        {
            foreground: 'ff0000',
            token: 'invalid',
        },
        {
            background: 'e71a1100',
            token: 'invalid.deprecated.trailing-whitespace',
        },
        {
            foreground: '000000',
            background: 'fafafafc',
            token: 'text source',
        },
        {
            foreground: 'aa0d91',
            token: 'meta.tag',
        },
        {
            foreground: 'aa0d91',
            token: 'declaration.tag',
        },
        {
            foreground: '000000',
            fontStyle: 'bold',
            token: 'support',
        },
        {
            foreground: 'aa0d91',
            token: 'storage',
        },
        {
            fontStyle: 'bold underline',
            token: 'entity.name.section',
        },
        {
            foreground: '000000',
            fontStyle: 'bold',
            token: 'entity.name.function.frame',
        },
        {
            foreground: '333333',
            token: 'meta.tag.preprocessor.xml',
        },
        {
            foreground: '994500',
            fontStyle: 'italic',
            token: 'entity.other.attribute-name',
        },
        {
            foreground: '881280',
            token: 'entity.name.tag',
        },
    ],
    colors: {
        'editor.foreground': '#000000',
        'editor.background': '#FFFFFF',
        'editor.selectionBackground': '#BAD6FD',
        'editor.lineHighlightBackground': '#EFEFFF',
        'editorCursor.foreground': '#000000',
        'editorWhitespace.foreground': '#B3B3B3F4',
    },
};
