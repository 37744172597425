"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviewWidget = void 0;
var react_1 = __importStar(require("react"));
var core_1 = require("@formily/core");
var react_2 = require("@formily/react");
var next_1 = require("@formily/next");
var next_2 = require("@alifd/next");
var formily_transformer_1 = require("@designable/formily-transformer");
var Text = function (_a) {
    var value = _a.value, mode = _a.mode, content = _a.content, props = __rest(_a, ["value", "mode", "content"]);
    var tagName = mode === 'normal' || !mode ? 'div' : mode;
    return react_1.default.createElement(tagName, props, value || content);
};
var SchemaField = react_2.createSchemaField({
    components: {
        Space: next_1.Space,
        FormGrid: next_1.FormGrid,
        FormLayout: next_1.FormLayout,
        FormTab: next_1.FormTab,
        FormCollapse: next_1.FormCollapse,
        ArrayTable: next_1.ArrayTable,
        ArrayCards: next_1.ArrayCards,
        FormItem: next_1.FormItem,
        DatePicker: next_1.DatePicker,
        Checkbox: next_1.Checkbox,
        Cascader: next_1.Cascader,
        Editable: next_1.Editable,
        Input: next_1.Input,
        Text: Text,
        NumberPicker: next_1.NumberPicker,
        Switch: next_1.Switch,
        Password: next_1.Password,
        PreviewText: next_1.PreviewText,
        Radio: next_1.Radio,
        Reset: next_1.Reset,
        Select: next_1.Select,
        Submit: next_1.Submit,
        TimePicker: next_1.TimePicker,
        Transfer: next_1.Transfer,
        TreeSelect: next_1.TreeSelect,
        Upload: next_1.Upload,
        Card: next_2.Card,
        Range: next_2.Range,
        Rating: next_2.Rating,
    },
});
var PreviewWidget = function (props) {
    var form = react_1.useMemo(function () { return core_1.createForm(); }, []);
    var _a = formily_transformer_1.transformToSchema(props.tree), formProps = _a.form, schema = _a.schema;
    return (react_1.default.createElement(next_1.Form, __assign({}, formProps, { form: form }),
        react_1.default.createElement(SchemaField, { schema: schema })));
};
exports.PreviewWidget = PreviewWidget;
