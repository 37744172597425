"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Text = void 0;
exports.Text = {
    'zh-CN': {
        title: '文本',
        settings: {
            'x-component-props': {
                content: '文本内容',
                mode: {
                    title: '文本类型',
                    dataSource: ['H1', 'H2', 'H3', 'Paragraph', 'Normal'],
                },
            },
        },
    },
    'en-US': {
        title: 'Text',
        settings: {
            'x-component-props': {
                content: 'Text Content',
                mode: {
                    title: 'Text Mode',
                    dataSource: ['H1', 'H2', 'H3', 'Paragraph', 'Normal'],
                },
            },
        },
    },
};
