"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Void = void 0;
exports.Void = {
    'zh-CN': {
        title: '虚拟容器',
    },
    'en-US': {
        title: 'Void',
    },
};
