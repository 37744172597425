"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    'ko-KR': {
        SettingComponents: {
            ValueInput: {
                expression: '표현식',
            },
            MonacoInput: {
                helpDocument: '도움말 문서',
            },
        },
    },
};
