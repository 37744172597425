"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    'zh-CN': {
        save: '保存',
        submit: '提交',
        cancel: '取消',
        reset: '重置',
        publish: '发布',
    },
    'en-US': {
        save: 'Save',
        submit: 'Submit',
        cancel: 'Cancel',
        reset: 'Reset',
        publish: 'Publish',
    },
};
