"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioGroup = void 0;
exports.RadioGroup = {
    'zh-CN': {
        title: '单选框组',
        settings: {
            'x-component-props': {
                shape: {
                    title: '形状',
                    dataSource: ['正常', '按钮'],
                },
            },
        },
    },
    'en-US': {
        title: 'Radio',
        settings: {
            'x-component-props': {
                shape: {
                    title: 'Shape',
                    dataSource: ['Normal', 'Button'],
                },
            },
        },
    },
};
