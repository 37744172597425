"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckboxGroup = void 0;
exports.CheckboxGroup = {
    'zh-CN': {
        title: '复选框组',
    },
    'en-US': {
        title: 'Checkbox',
    },
};
