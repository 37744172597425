"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rating = void 0;
exports.Rating = {
    'zh-CN': {
        title: '评分器',
        settings: {
            'x-component-props': {
                count: '评分总数',
                showGrade: '星星总数',
                allowHalf: '半星评分',
                allowClear: {
                    title: '点击后清除',
                    tooltip: '评分后再次点击评分清除评分',
                },
            },
        },
    },
    'en-US': {
        title: 'Rating',
        settings: {
            'x-component-props': {
                count: 'Star Count',
                showGrade: 'Grade',
                allowHalf: 'Allow Half Star',
                allowClear: {
                    title: 'Clear After Clicking',
                    tooltip: 'After scoring, click the Rating again to clear the count',
                },
            },
        },
    },
};
