"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractMutationNodeEvent = void 0;
var AbstractMutationNodeEvent = /** @class */ (function () {
    function AbstractMutationNodeEvent(data) {
        this.data = data;
    }
    return AbstractMutationNodeEvent;
}());
exports.AbstractMutationNodeEvent = AbstractMutationNodeEvent;
