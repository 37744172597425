"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cascader = void 0;
exports.Cascader = {
    type: 'object',
    properties: {
        expandTriggerType: {
            type: 'string',
            enum: ['click', 'hover'],
            'x-decorator': 'FormItem',
            'x-component': 'Radio.Group',
            'x-component-props': {
                defaultValue: 'click',
                optionType: 'button',
            },
        },
        useVirtual: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        multiple: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-component-props': {
                defaultValue: false,
            },
        },
        canOnlySelectLeaf: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-reactions': {
                dependencies: ['.multiple'],
                fulfill: {
                    state: {
                        visible: '{{!$deps[0]}}',
                    },
                },
            },
        },
        canOnlyCheckLeaf: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-reactions': {
                dependencies: ['.multiple'],
                fulfill: {
                    state: {
                        visible: '{{$deps[0]}}',
                    },
                },
            },
        },
        checkStrictly: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        immutable: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
    },
};
