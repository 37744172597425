"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcElementLayout = exports.calcElementOuterWidth = void 0;
var InlineLayoutTagNames = new Set([
    'A',
    'ABBR',
    'ACRONYM',
    'AUDIO',
    'B',
    'BDI',
    'BDO',
    'BIG',
    'BR',
    'BUTTON',
    'CANVAS',
    'CITE',
    'CODE',
    'DATA',
    'DATALIST',
    'DEL',
    'DFN',
    'EM',
    'EMBED',
    'I',
    'IFRAME',
    'IMG',
    'INS',
    'KBD',
    'LABEL',
    'MAP',
    'MARK',
    'METER',
    'NOSCRIPT',
    'OBJECT',
    'OUTPUT',
    'PICTURE',
    'PROGRESS',
    'Q',
    'RUBY',
    'S',
    'SAMP',
    'SELECT',
    'SLOT',
    'SMALL',
    'STRONG',
    'SUB',
    'SUP',
    'SVG',
    'TEMPLATE',
    'TEXTAREA',
    'TIME',
    'U',
    'TT',
    'VAR',
    'VIDEO',
    'WBR',
    'INPUT',
    'SPAN',
]);
var calcElementOuterWidth = function (innerWidth, style) {
    return (innerWidth +
        parseFloat(style.marginLeft) +
        parseFloat(style.marginRight) +
        parseFloat(style.paddingLeft) +
        parseFloat(style.paddingRight) +
        parseFloat(style.borderLeftWidth) +
        parseFloat(style.borderRightWidth));
};
exports.calcElementOuterWidth = calcElementOuterWidth;
var calcElementLayout = function (element) {
    if (!element)
        return 'vertical';
    var parent = element.parentElement;
    var tagName = element.tagName;
    var parentTagName = parent.tagName;
    var style = getComputedStyle(element);
    var parentStyle = getComputedStyle(parent);
    var isNotFullWidth = function () {
        var innerWidth = element.getBoundingClientRect().width;
        var outerWidth = exports.calcElementOuterWidth(innerWidth, style);
        var parentInnerWidth = parent.getBoundingClientRect().width;
        return outerWidth.toFixed(0) < parentInnerWidth.toFixed(0);
    };
    if (tagName === 'TH' || tagName === 'TD') {
        if (parentTagName === 'TR')
            return 'horizontal';
    }
    if (parentStyle.display === 'flex' && parentStyle.flexDirection === 'row')
        return 'horizontal';
    if (parentStyle.display === 'grid') {
        if (isNotFullWidth()) {
            return 'horizontal';
        }
    }
    if (InlineLayoutTagNames.has(tagName)) {
        if (style.display === 'block') {
            if (style.float === 'left' || style.float === 'right') {
                if (isNotFullWidth()) {
                    return 'horizontal';
                }
            }
            return 'vertical';
        }
        return 'horizontal';
    }
};
exports.calcElementLayout = calcElementLayout;
