"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FulfillRunHelper = exports.DecoratorPropsHelper = exports.ComponentPropsHelper = exports.DataSourceHelper = exports.AnyHelper = exports.StringHelper = exports.PatternHelper = exports.DisplayHelper = exports.BooleanHelper = exports.GlobalHelper = void 0;
exports.GlobalHelper = "\n/** \n * You can use the built-in context variables\n * \n * 1. `$self` is the current Field Model \n * \n * 2. `$form` is the current Form Model \n * \n * 3. `$deps` is the dependencies value\n * \n * 4. `$observable` function is used to create an persistent observable state object\n *\n * 5. `$memo` function is is used to create a persistent data\n * \n * 6. `$effect` function is used to handle side-effect logic\n * \n * 7. `$props` function is used to set component props to current field\n * \n * Document Links\n * \n * https://react.formilyjs.org/api/shared/schema#%E5%86%85%E7%BD%AE%E8%A1%A8%E8%BE%BE%E5%BC%8F%E4%BD%9C%E7%94%A8%E5%9F%9F\n **/\n";
exports.BooleanHelper = "\n/** \n * Example 1\n * Static Boolean\n **/\n\nfalse\n\n/** \n * Example 2\n * Equal Calculation\n **/\n\n$deps.VariableName === 'TARGET_VALUE'\n\n/** \n * Example 3\n * Not Equal Calculation\n **/\n\n$deps.VariableName !== 'TARGET_VALUE'\n\n/** \n * Example 4\n * And Logic Calculation\n **/\n\n$deps.VariableName1 && $deps.VariableName2\n\n/** \n * Example 5\n * Grater Logic Calculation\n **/\n\n$deps.VariableName > 100\n\n/** \n * Example 6\n * Not Logic Calculation\n **/\n\n!$deps.VariableName\n\n" + exports.GlobalHelper + "\n";
exports.DisplayHelper = "\n/** \n * Example 1\n * Static Mode\n **/\n\n'none'\n\n/** \n * Example 2\n * Equal Condition Associated\n **/\n\n$deps.VariableName === 'TARGET_VALUE' ? 'visible' : 'none'\n\n/** \n * Example 3\n * Not Equal Condition Associated\n **/\n\n$deps.VariableName !== 'TARGET_VALUE' ? 'visible' : 'hidden'\n\n/** \n * Example 4\n * And Logic Condition Associated\n **/\n\n$deps.VariableName1 && $deps.VariableName2 ? 'visible' : 'none'\n\n/** \n * Example 5\n * Grater Logic Condition Associated\n **/\n\n$deps.VariableName > 100 ? 'visible' : 'hidden'\n\n/** \n * Example 6\n * Not Logic Condition Associated\n **/\n\n!$deps.VariableName ? 'visible' : 'none'\n\n" + exports.GlobalHelper + "\n";
exports.PatternHelper = "\n/** \n * Example 1\n * Static Mode\n **/\n\n'readPretty'\n\n/** \n * Example 2\n * Equal Condition Associated\n **/\n\n$deps.VariableName === 'TARGET_VALUE' ? 'editable' : 'disabled'\n\n/** \n * Example 3\n * Not Equal Condition Associated\n **/\n\n$deps.VariableName !== 'TARGET_VALUE' ? 'editable' : 'readOnly'\n\n/** \n * Example 4\n * And Logic Condition Associated\n **/\n\n$deps.VariableName1 && $deps.VariableName2 ? 'editable' : 'readPretty'\n\n/** \n * Example 5\n * Grater Logic Condition Associated\n **/\n\n$deps.VariableName > 100 ? 'editable' : 'readOnly'\n\n/** \n * Example 6\n * Not Logic Condition Associated\n **/\n\n!$deps.VariableName ? 'editable' : 'disabled'\n\n" + exports.GlobalHelper + "\n";
exports.StringHelper = "\n/** \n * Example 1\n * Static String\n **/\n\n'Normal String Text'\n\n/** \n * Example 2\n * Associated String\n **/\n\n$deps.VariableName === 'TARGET_VALUE' ? 'Associated String Text' : ''\n\n" + exports.GlobalHelper + "\n";
exports.AnyHelper = "\n/** \n * Example 1\n * String Type\n **/\n\n'String'\n\n/** \n * Example 2\n * String Array\n **/\n\n['StringArray']\n\n/** \n * Example 3\n * Object Array\n **/\n\n[{ key: 'ObjectArray' }]\n\n/** \n * Example 4\n * Boolean\n **/\n\ntrue\n\n/** \n * Example 5\n * RegExp\n **/\n\n/d+/\n\n/** \n * Example 1\n * Associated String Value\n **/\n\n$deps.VariableName + 'Compose String'\n\n/** \n * Example 2\n * Associated Array Value\n **/\n\n[ $deps.VariableName ]\n\n/** \n * Example 3\n * Associated Object Value\n **/\n\n{\n  key : $deps.VariableName\n}\n\n/** \n * Example 4\n * Associated Boolean Value\n **/\n\n!$deps.VariableName\n\n" + exports.GlobalHelper + "\n";
exports.DataSourceHelper = "\n/** \n * Example 1\n * Static DataSource\n **/\n\n[\n  { label : \"item1\", value: \"1\" },\n  { label : \"item2\", value: \"2\" }\n]\n\n/** \n * Example 2\n * Associated DataSource\n **/\n\n[\n  { label : \"item1\", value: \"1\" },\n  { label : \"item2\", value: \"2\" },\n  ...$deps.VariableName\n]\n\n" + exports.GlobalHelper + "\n";
exports.ComponentPropsHelper = "\n/** \n * Example 1\n * Static Props\n **/\n\n{\n  placeholder: \"This is placeholder\"\n}\n\n/** \n * Example 2\n * Associated Props\n **/\n\n{\n  placeholder: $deps.VariableName\n}\n\n" + exports.GlobalHelper + "\n";
exports.DecoratorPropsHelper = "\n/** \n * Example 1\n * Static Props\n **/\n\n{\n  labelCol:6\n}\n\n/** \n * Example 2\n * Associated Props\n **/\n\n{\n  labelCol: $deps.VariableName\n}\n\n" + exports.GlobalHelper + "\n";
exports.FulfillRunHelper = "\n/** \n * Example 1\n * Async Select\n **/\n\n$effect(()=>{\n  $self.loading = true\n  fetch('//some.domain/getSomething')\n    .then(response=>response.json())\n    .then(({ data })=>{\n      $self.loading = false\n      $self.dataSource = data\n    },()=>{\n      $self.loading = false\n    })\n},[])\n  \n\n/** \n * Example 2\n * Async Search Select\n **/\n\nconst state = $observable({\n  keyword:''\n})\n\n$props({\n  onSearch(keyword){\n    state.keyword = keyword\n  }\n})\n\n$effect(()=>{\n  $self.loading = true\n  fetch(`//some.domain/getSomething?q=${state.keyword}`)\n    .then(response=>response.json())\n    .then(({ data })=>{\n      $self.loading = false\n      $self.dataSource = data\n    },()=>{\n      $self.loading = false\n    })\n},[ state.keyword ])\n\n/** \n * Example 3\n * Async Associated Select\n **/\n\nconst state = $observable({\n  keyword:''\n})\n\n$props({\n  onSearch(keyword){\n    state.keyword = keyword\n  }\n})\n\n$effect(()=>{\n  $self.loading = true\n  fetch(`//some.domain/getSomething?q=${state.keyword}&other=${$deps.VariableName}`)\n    .then(response=>response.json())\n    .then(({ data })=>{\n      $self.loading = false\n      $self.dataSource = data\n    },()=>{\n      $self.loading = false\n    })\n},[ state.keyword, $deps.VariableName ])\n\n" + exports.GlobalHelper + "\n";
