"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractWorkspaceEvent = void 0;
var AbstractWorkspaceEvent = /** @class */ (function () {
    function AbstractWorkspaceEvent(data) {
        this.data = data;
    }
    return AbstractWorkspaceEvent;
}());
exports.AbstractWorkspaceEvent = AbstractWorkspaceEvent;
