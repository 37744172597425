"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormGridColumn = exports.FormGrid = void 0;
exports.FormGrid = {
    'zh-CN': {
        title: '网格布局',
        addGridColumn: '添加网格列',
        settings: {
            'x-component-props': {
                minWidth: '最小宽度',
                minColumns: '最小列数',
                maxWidth: '最大宽度',
                maxColumns: '最大列数',
                breakpoints: '响应式断点',
                columnGap: '列间距',
                rowGap: '行间距',
                colWrap: '自动换行',
            },
        },
    },
    'en-US': {
        title: 'Grid',
        addGridColumn: 'Add Grid Column',
        settings: {
            'x-component-props': {
                minWidth: 'Min Width',
                minColumns: 'Min Columns',
                maxWidth: 'Max Width',
                maxColumns: 'Max Columns',
                breakpoints: 'Breakpoints',
                columnGap: 'Column Gap',
                rowGap: 'Row Gap',
                colWrap: 'Col Wrap',
            },
        },
    },
};
exports.FormGridColumn = {
    'zh-CN': {
        title: '网格列',
        settings: {
            'x-component-props': {
                gridSpan: '跨列栏数',
            },
        },
    },
    'en-US': {
        title: 'Grid Column',
        settings: {
            'x-component-props': {
                gridSpan: 'Grid Span',
            },
        },
    },
};
