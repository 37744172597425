"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadInitialSchema = exports.saveSchema = void 0;
var formily_transformer_1 = require("@designable/formily-transformer");
var antd_1 = require("antd");
var saveSchema = function (designer) {
    localStorage.setItem('formily-schema', JSON.stringify(formily_transformer_1.transformToSchema(designer.getCurrentTree())));
    antd_1.message.success('Save Success');
};
exports.saveSchema = saveSchema;
var loadInitialSchema = function (designer) {
    try {
        designer.setCurrentTree(formily_transformer_1.transformToTreeNode(JSON.parse(localStorage.getItem('formily-schema'))));
    }
    catch (_a) { }
};
exports.loadInitialSchema = loadInitialSchema;
