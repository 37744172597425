"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Transfer = void 0;
exports.Transfer = {
    type: 'object',
    properties: {
        id: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
        mode: {
            type: 'string',
            enum: ['normal', 'simple'],
            'x-decorator': 'FormItem',
            'x-component': 'Radio.Group',
            'x-component-props': {
                defaultValue: 'normal',
                optionType: 'button',
            },
        },
        leftDisabled: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        rightDisabled: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        showSearch: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        filter: {
            'x-decorator': 'FormItem',
            'x-component': 'ValueInput',
            'x-component-props': {
                include: ['EXPRESSION'],
                helpCode: 'function(searchedValue: string, data: object): boolean {\n  return true\n}',
            },
        },
        searchPlaceholder: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
        notFoundContent: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
        titles: {
            'x-decorator': 'FormItem',
            'x-component': 'ValueInput',
            'x-component-props': {
                include: ['EXPRESSION'],
            },
        },
        sortable: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        useVirtual: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        showCheckAll: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-component-props': {
                defaultChecked: true,
            },
        },
    },
};
