"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractHistoryEvent = void 0;
var AbstractHistoryEvent = /** @class */ (function () {
    function AbstractHistoryEvent(data) {
        this.data = data;
    }
    return AbstractHistoryEvent;
}());
exports.AbstractHistoryEvent = AbstractHistoryEvent;
