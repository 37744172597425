"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cascader = void 0;
exports.Cascader = {
    'zh-CN': {
        title: '联级选择',
        settings: {
            'x-component-props': {
                expandTriggerType: {
                    title: '展开触发',
                    dataSource: ['点击', '移入'],
                },
                canOnlySelectLeaf: '单选仅叶节点',
                canOnlyCheckLeaf: '多选仅叶节点',
                checkStrictly: '父子节点选中不关联',
            },
        },
    },
    'en-US': {
        title: 'Cascader',
        settings: {
            'x-component-props': {
                expandTriggerType: {
                    title: 'Expand Trigger',
                    dataSource: ['Click', 'Hover'],
                },
                canOnlySelectLeaf: 'Only Select Leaf',
                canOnlyCheckLeaf: 'Only Check Leaf',
                checkStrictly: 'Check Strictly',
            },
        },
    },
};
