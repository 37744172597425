"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CSSStyle = void 0;
exports.CSSStyle = {
    type: 'void',
    properties: {
        'style.width': {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'SizeInput',
        },
        'style.height': {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'SizeInput',
        },
        'style.display': {
            'x-component': 'DisplayStyleSetter',
        },
        'style.background': {
            'x-component': 'BackgroundStyleSetter',
        },
        'style.boxShadow': {
            'x-component': 'BoxShadowStyleSetter',
        },
        'style.font': {
            'x-component': 'FontStyleSetter',
        },
        'style.margin': {
            'x-component': 'BoxStyleSetter',
        },
        'style.padding': {
            'x-component': 'BoxStyleSetter',
        },
        'style.borderRadius': {
            'x-component': 'BorderRadiusStyleSetter',
        },
        'style.border': {
            'x-component': 'BorderStyleSetter',
        },
        'style.opacity': {
            'x-decorator': 'FormItem',
            'x-component': 'Slider',
            'x-component-props': {
                defaultValue: 1,
                min: 0,
                max: 1,
                step: 0.01,
            },
        },
    },
};
