"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Card = void 0;
exports.Card = {
    'zh-CN': {
        title: '卡片',
        settings: {
            'x-component-props': {
                subTitle: '子标题',
                showTitleBullet: '标题项目符号',
                showHeadDivider: '标题分割线',
                contentHeight: '内容高度',
            },
        },
    },
    'en-US': {
        title: 'Card',
        settings: {
            'x-component-props': {
                subTitle: 'Subtitle',
                showTitleBullet: 'Title Bullet',
                showHeadDivider: 'Title Divider',
                contentHeight: 'Content Height',
            },
        },
    },
};
