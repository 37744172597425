"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionsWidget = void 0;
var react_1 = __importStar(require("react"));
var antd_1 = require("antd");
var icons_1 = require("@ant-design/icons");
var react_2 = require("@designable/react");
var core_1 = require("@designable/core");
var react_3 = require("@formily/react");
var service_1 = require("../service");
exports.ActionsWidget = react_3.observer(function () {
    var designer = react_2.useDesigner();
    react_1.useEffect(function () {
        service_1.loadInitialSchema(designer);
    }, []);
    var supportLocales = ['zh-cn', 'en-us', 'ko-kr'];
    react_1.useEffect(function () {
        if (!supportLocales.includes(core_1.GlobalRegistry.getDesignerLanguage())) {
            core_1.GlobalRegistry.setDesignerLanguage('zh-cn');
        }
    }, []);
    return (react_1.default.createElement(antd_1.Space, { style: { marginRight: 10 } },
        react_1.default.createElement(antd_1.Button, { href: "https://designable-antd.formilyjs.org" }, "Ant Design"),
        react_1.default.createElement(antd_1.Radio.Group, { value: core_1.GlobalRegistry.getDesignerLanguage(), optionType: "button", options: [
                { label: 'English', value: 'en-us' },
                { label: '简体中文', value: 'zh-cn' },
                { label: '한국어', value: 'ko-kr' },
            ], onChange: function (e) {
                core_1.GlobalRegistry.setDesignerLanguage(e.target.value);
            } }),
        react_1.default.createElement(antd_1.Button, { href: "https://github.com/alibaba/designable", target: "_blank" },
            react_1.default.createElement(icons_1.GithubOutlined, null),
            "Github"),
        react_1.default.createElement(antd_1.Button, { onClick: function () {
                service_1.saveSchema(designer);
            } },
            react_1.default.createElement(react_2.TextWidget, null, "Save")),
        react_1.default.createElement(antd_1.Button, { type: "primary", onClick: function () {
                service_1.saveSchema(designer);
            } },
            react_1.default.createElement(react_2.TextWidget, null, "Publish"))));
});
