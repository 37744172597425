"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcRectByStartEndPoint = exports.calcBoundingRect = exports.calcRelativeOfPointToRect = exports.isNearAfter = exports.calcDistancePointToEdge = exports.calcDistanceOfPointToRect = exports.calcQuadrantOfPointToRect = exports.isCrossRectInRect = exports.isRectInRect = exports.getRectPoints = exports.isPointInRect = exports.RectQuadrant = exports.Point = void 0;
var Point = /** @class */ (function () {
    function Point(x, y) {
        this.x = x;
        this.y = y;
    }
    return Point;
}());
exports.Point = Point;
var RectQuadrant;
(function (RectQuadrant) {
    RectQuadrant["Inner1"] = "I1";
    RectQuadrant["Inner2"] = "I2";
    RectQuadrant["Inner3"] = "I3";
    RectQuadrant["Inner4"] = "I4";
    RectQuadrant["Outer1"] = "O1";
    RectQuadrant["Outer2"] = "O2";
    RectQuadrant["Outer3"] = "O3";
    RectQuadrant["Outer4"] = "O4";
})(RectQuadrant = exports.RectQuadrant || (exports.RectQuadrant = {}));
function isPointInRect(point, rect, sensitive) {
    if (sensitive === void 0) { sensitive = true; }
    var boundSensor = function (value) {
        if (!sensitive)
            return 0;
        var sensor = value * 0.1;
        if (sensor > 20)
            return 20;
        if (sensor < 10)
            return 10;
        return sensor;
    };
    return (point.x >= rect.x + boundSensor(rect.width) &&
        point.x <= rect.x + rect.width - boundSensor(rect.width) &&
        point.y >= rect.y + boundSensor(rect.height) &&
        point.y <= rect.y + rect.height - boundSensor(rect.height));
}
exports.isPointInRect = isPointInRect;
function getRectPoints(source) {
    var p1 = new Point(source.x, source.y);
    var p2 = new Point(source.x + source.width, source.y);
    var p3 = new Point(source.x + source.width, source.y + source.height);
    var p4 = new Point(source.x, source.y + source.height);
    return [p1, p2, p3, p4];
}
exports.getRectPoints = getRectPoints;
function isRectInRect(target, source) {
    var _a = __read(getRectPoints(source), 4), p1 = _a[0], p2 = _a[1], p3 = _a[2], p4 = _a[3];
    return (isPointInRect(p1, target, false) &&
        isPointInRect(p2, target, false) &&
        isPointInRect(p3, target, false) &&
        isPointInRect(p4, target, false));
}
exports.isRectInRect = isRectInRect;
function isCrossRectInRect(target, source) {
    var targetCenterPoint = new Point(target.x + target.width / 2, target.y + target.height / 2);
    var sourceCenterPoint = new Point(source.x + source.width / 2, source.y + source.height / 2);
    return (Math.abs(targetCenterPoint.x - sourceCenterPoint.x) <=
        target.width / 2 + source.width / 2 &&
        Math.abs(targetCenterPoint.y - sourceCenterPoint.y) <=
            target.height / 2 + source.height / 2);
}
exports.isCrossRectInRect = isCrossRectInRect;
/**
 * 计算点在矩形的哪个象限
 * @param point
 * @param rect
 */
function calcQuadrantOfPointToRect(point, rect) {
    var isInner = isPointInRect(point, rect);
    if (point.x <= rect.x + rect.width / 2) {
        if (point.y <= rect.y + rect.height / 2) {
            if (isInner) {
                return RectQuadrant.Inner1;
            }
            else {
                return RectQuadrant.Outer1;
            }
        }
        else {
            if (isInner) {
                return RectQuadrant.Inner4;
            }
            else {
                return RectQuadrant.Outer4;
            }
        }
    }
    else {
        if (point.y <= rect.y + rect.height / 2) {
            if (isInner) {
                return RectQuadrant.Inner2;
            }
            else {
                return RectQuadrant.Outer2;
            }
        }
        else {
            if (isInner) {
                return RectQuadrant.Inner3;
            }
            else {
                return RectQuadrant.Outer3;
            }
        }
    }
}
exports.calcQuadrantOfPointToRect = calcQuadrantOfPointToRect;
function calcDistanceOfPointToRect(point, rect) {
    var minX = Math.min(Math.abs(point.x - rect.x), Math.abs(point.x - (rect.x + rect.width)));
    var minY = Math.min(Math.abs(point.y - rect.y), Math.abs(point.y - (rect.y + rect.height)));
    if (point.x >= rect.x && point.x <= rect.x + rect.width) {
        minX = 0;
    }
    if (point.y >= rect.y && point.y <= rect.y + rect.height) {
        minY = 0;
    }
    return Math.sqrt(Math.pow(minX, 2) + Math.pow(minY, 2));
}
exports.calcDistanceOfPointToRect = calcDistanceOfPointToRect;
function calcDistancePointToEdge(point, rect) {
    var distanceTop = Math.abs(point.y - rect.y);
    var distanceBottom = Math.abs(point.y - (rect.y + rect.height));
    var distanceLeft = Math.abs(point.x - rect.x);
    var distanceRight = Math.abs(point.x - (rect.x + rect.width));
    return Math.min(distanceTop, distanceBottom, distanceLeft, distanceRight);
}
exports.calcDistancePointToEdge = calcDistancePointToEdge;
function isNearAfter(point, rect, inline) {
    if (inline === void 0) { inline = false; }
    if (inline) {
        return (Math.abs(point.x - rect.x) + Math.abs(point.y - rect.y) >
            Math.abs(point.x - (rect.x + rect.width)) +
                Math.abs(point.y - (rect.y + rect.height)));
    }
    return Math.abs(point.y - rect.y) > Math.abs(point.y - (rect.y + rect.height));
}
exports.isNearAfter = isNearAfter;
/**
 * 计算点鱼矩形的相对位置信息
 * @param point
 * @param rect
 */
function calcRelativeOfPointToRect(point, rect) {
    var distance = calcDistanceOfPointToRect(point, rect);
    var quadrant = calcQuadrantOfPointToRect(point, rect);
    return {
        quadrant: quadrant,
        distance: distance,
    };
}
exports.calcRelativeOfPointToRect = calcRelativeOfPointToRect;
function calcBoundingRect(rects) {
    if (!(rects === null || rects === void 0 ? void 0 : rects.length))
        return;
    if ((rects === null || rects === void 0 ? void 0 : rects.length) === 1 && !rects[0])
        return;
    var minTop = Infinity;
    var maxBottom = -Infinity;
    var minLeft = Infinity;
    var maxRight = -Infinity;
    rects.forEach(function (item) {
        var rect = typeof DOMRect !== 'undefined' &&
            new DOMRect(item.x, item.y, item.width, item.height);
        if (rect.top <= minTop) {
            minTop = rect.top;
        }
        if (rect.bottom >= maxBottom) {
            maxBottom = rect.bottom;
        }
        if (rect.left <= minLeft) {
            minLeft = rect.left;
        }
        if (rect.right >= maxRight) {
            maxRight = rect.right;
        }
    });
    return (typeof DOMRect !== 'undefined' &&
        new DOMRect(minLeft, minTop, maxRight - minLeft, maxBottom - minTop));
}
exports.calcBoundingRect = calcBoundingRect;
function calcRectByStartEndPoint(startPoint, endPoint, scrollX, scrollY) {
    if (scrollX === void 0) { scrollX = 0; }
    if (scrollY === void 0) { scrollY = 0; }
    var drawStartX = 0, drawStartY = 0;
    if (endPoint.x + scrollX >= startPoint.x &&
        endPoint.y + scrollY >= startPoint.y) {
        //4象限
        drawStartX = startPoint.x;
        drawStartY = startPoint.y;
        return (typeof DOMRect !== 'undefined' &&
            new DOMRect(drawStartX - scrollX, drawStartY - scrollY, Math.abs(endPoint.x - startPoint.x + scrollX), Math.abs(endPoint.y - startPoint.y + scrollY)));
    }
    else if (endPoint.x + scrollX < startPoint.x &&
        endPoint.y + scrollY < startPoint.y) {
        //1象限
        drawStartX = endPoint.x;
        drawStartY = endPoint.y;
        return (typeof DOMRect !== 'undefined' &&
            new DOMRect(drawStartX, drawStartY, Math.abs(endPoint.x - startPoint.x) - scrollX, Math.abs(endPoint.y - startPoint.y) - scrollY));
    }
    else if (endPoint.x + scrollX < startPoint.x &&
        endPoint.y + scrollY >= startPoint.y) {
        //3象限
        drawStartX = endPoint.x;
        drawStartY = startPoint.y;
        return (typeof DOMRect !== 'undefined' &&
            new DOMRect(drawStartX - scrollX, drawStartY - scrollY, Math.abs(endPoint.x - startPoint.x + scrollX), Math.abs(endPoint.y - startPoint.y + scrollY)));
    }
    else {
        //2象限
        drawStartX = startPoint.x;
        drawStartY = endPoint.y;
        return (typeof DOMRect !== 'undefined' &&
            new DOMRect(drawStartX, drawStartY, Math.abs(endPoint.x - startPoint.x) - scrollX, Math.abs(endPoint.y - startPoint.y) - scrollY));
    }
}
exports.calcRectByStartEndPoint = calcRectByStartEndPoint;
