"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Switch = void 0;
exports.Switch = {
    'zh-CN': {
        title: '开关',
        settings: {
            'x-component-props': {
                size: {
                    title: '尺寸',
                    dataSource: ['小', '中', '继承'],
                },
                checkedChildren: '打开时内容',
                unCheckedChildren: '关闭时内容',
            },
        },
    },
    'en-US': {
        title: 'Switch',
        settings: {
            'x-component-props': {
                size: {
                    title: 'Size',
                    dataSource: ['Small', 'Medium', 'Inherit'],
                },
                checkedChildren: 'Open Content',
                unCheckedChildren: 'Close Content',
            },
        },
    },
};
