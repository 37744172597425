"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    'zh-CN': {
        panels: {
            Component: '组件',
            OutlinedTree: '大纲树',
            PropertySettings: '属性配置',
            History: '历史记录',
        },
    },
    'en-US': {
        panels: {
            Component: 'Component',
            OutlinedTree: 'Outlined Tree',
            PropertySettings: 'Property Settings',
            History: 'History',
        },
    },
    'ko-KR': {
        panels: {
            Component: '컴포넌트',
            OutlinedTree: '트리 노드',
            PropertySettings: '속성 설정',
            History: '기록',
        },
    },
};
