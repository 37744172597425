"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    'zh-CN': {
        icons: {
            Image: '图片',
            ImageSize: '图片尺寸',
            Position: '位置',
            Number: '数字',
            Text: '文本',
            Expression: '表达式',
            RichText: '富文本',
            Boolean: '布尔',
            Repeat: '重复',
            Top: '上',
            Left: '左',
            Right: '右',
            Bottom: '下',
            TopLeft: '左上',
            TopRight: '右上',
            BottomRight: '右下',
            BottomLeft: '左下',
            DisplayBlock: '区块',
            DisplayInlineBlock: '内联区块',
            DisplayInline: '文本内联',
            DisplayFlex: '弹性盒子',
            FlexDirectionRow: '横向',
            FlexDirectionColumn: '纵向',
            FlexAlignContentCenter: '居中',
            FlexAlignContentStart: '头部',
            FlexAlignContentEnd: '尾部',
            FlexAlignContentSpaceAround: 'Space Around',
            FlexAlignContentSpaceBetween: 'Space Between',
            FlexAlignContentStretch: 'Stretch',
            FlexJustifyCenter: '居中',
            FlexJustifyStart: '头部',
            FlexJustifyEnd: '尾部',
            FlexJustifySpaceBetween: 'Space Between',
            FlexJustifySpaceAround: 'Space Around',
            FlexJustifySpaceEvenly: 'Space Evenly',
            FlexAlignItemsCenter: '居中',
            FlexAlignItemsStart: '头部',
            FlexAlignItemsEnd: '尾部',
            FlexAlignItemsStretch: 'Stretch',
            FlexAlignItemsBaseline: 'Baseline',
            FlexNoWrap: '不换行',
            FlexWrap: '换行',
            AxisX: 'X轴',
            AxisY: 'Y轴',
            Blur: '模糊度',
            Shadow: '阴影扩展',
            FontWeight: '字重',
            FontStyle: '风格',
            NormalFontStyle: '标准',
            ItalicFontStyle: '斜体',
            FontColor: '颜色',
            FontSize: '大小',
            LineHeight: '行高',
            TextDecoration: '修饰',
            TextUnderline: '下划线',
            TextLineThrough: '中横线',
            TextAlign: '对齐',
            TextAlignLeft: '左对齐',
            TextAlignCenter: '居中',
            TextAlignRight: '右对齐',
            TextAlignJustify: '自适应',
        },
    },
    'en-US': {
        icons: {
            Image: 'Image',
            ImageSize: 'Image Size',
            Boolean: 'Boolean',
            Number: 'Number',
            Text: 'Text',
            Expression: 'Expression',
            RichText: 'Rich Text',
            Position: 'Position',
            Repeat: 'Repeat',
            Top: 'Top',
            Left: 'Left',
            Right: 'Right',
            Bottom: 'Bottom',
            TopLeft: 'Top Left',
            TopRight: 'Top Right',
            BottomRight: 'Bottom Right',
            BottomLeft: 'Bottom Left',
            DisplayBlock: 'Block',
            DisplayInlineBlock: 'Inline Block',
            DisplayInline: 'Inline',
            DisplayFlex: 'Flex',
            FlexDirectionRow: 'Horizontal',
            FlexDirectionColumn: 'Vertical',
            FlexAlignContentCenter: 'Center',
            FlexAlignContentStart: 'Start',
            FlexAlignContentEnd: 'End',
            FlexAlignContentSpaceAround: 'Space Around',
            FlexAlignContentSpaceBetween: 'Space Between',
            FlexAlignContentStretch: 'Stretch',
            FlexJustifyCenter: 'Center',
            FlexJustifyStart: 'Start',
            FlexJustifyEnd: 'End',
            FlexJustifySpaceBetween: 'Space Between',
            FlexJustifySpaceAround: 'Space Around',
            FlexJustifySpaceEvenly: 'Space Evenly',
            FlexAlignItemsCenter: 'Center',
            FlexAlignItemsStart: 'Start',
            FlexAlignItemsEnd: 'End',
            FlexAlignItemsStretch: 'Stretch',
            FlexAlignItemsBaseline: 'Baseline',
            FlexNoWrap: 'No Wrap',
            FlexWrap: 'Wrap',
            AxisX: 'X Axis',
            AxisY: 'Y Axis',
            Blur: 'Blur',
            Shadow: 'Shadow Spread',
            FontWeight: 'Font Weight',
            FontStyle: 'Font Style',
            NormalFontStyle: 'Normal',
            ItalicFontStyle: 'Italic',
            FontColor: 'Color',
            FontSize: 'Font Size',
            LineHeight: 'Line Height',
            TextDecoration: 'Decoration',
            TextUnderline: 'Underline',
            TextLineThrough: 'Line Through',
            TextAlign: 'Align',
            TextAlignLeft: 'Left',
            TextAlignCenter: 'Center',
            TextAlignRight: 'Right',
            TextAlignJustify: 'Justify',
        },
    },
};
