"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberPicker = void 0;
exports.NumberPicker = {
    'zh-CN': {
        title: '数字输入',
        settings: {
            'x-component-props': {
                type: {
                    title: '类型',
                    dataSource: ['正常', '内联'],
                },
                precision: '保留小数点后位数',
                innerAfter: '后缀',
                device: {
                    title: '预设设备',
                    dataSource: ['桌面', '手机', '平板'],
                },
                hasTrigger: '展示调整按钮',
                alwaysShowTrigger: '总是展示调整按钮',
            },
        },
    },
    'en-US': {
        title: 'NumberInput',
        settings: {
            'x-component-props': {
                type: {
                    title: 'Type',
                    dataSource: ['Normal', 'Inline'],
                },
                precision: 'Precision',
                innerAfter: 'Inner After',
                device: {
                    title: 'Preset Device',
                    dataSource: ['Desktop', 'Phone', 'Pad'],
                },
                hasTrigger: 'Trigger',
                alwaysShowTrigger: 'Always Show Trigger',
            },
        },
    },
};
