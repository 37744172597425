"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Upload = void 0;
exports.Upload = {
    type: 'object',
    properties: {
        action: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
        shape: {
            type: 'string',
            enum: [null, 'card'],
            'x-decorator': 'FormItem',
            'x-component': 'Radio.Group',
            'x-component-props': {
                defaultValue: null,
                optionType: 'button',
            },
        },
        accept: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
        data: {
            'x-decorator': 'FormItem',
            'x-component': 'ValueInput',
            'x-component-props': {
                include: ['EXPRESSION'],
            },
        },
        headers: {
            'x-decorator': 'FormItem',
            'x-component': 'ValueInput',
            'x-component-props': {
                include: ['EXPRESSION'],
            },
        },
        withCredentials: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-component-props': {
                defaultChecked: true,
            },
        },
        timeout: {
            type: 'number',
            'x-decorator': 'FormItem',
            'x-component': 'NumberPicker',
        },
        method: {
            type: 'string',
            enum: ['post', 'put'],
            'x-decorator': 'FormItem',
            'x-component': 'Radio.Group',
            'x-component-props': {
                defaultValue: 'post',
                optionType: 'button',
            },
        },
        request: {
            'x-decorator': 'FormItem',
            'x-component': 'ValueInput',
            'x-component-props': {
                include: ['EXPRESSION'],
                helpCode: "// Function(option: Object) => Object",
            },
        },
        name: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
        listType: {
            type: 'string',
            enum: [null, 'text', 'image', 'card'],
            'x-decorator': 'FormItem',
            'x-component': 'Select',
            'x-component-props': {
                defaultValue: null,
            },
        },
        limit: {
            type: 'number',
            'x-decorator': 'FormItem',
            'x-component': 'NumberPicker',
        },
        dragable: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        useDataURL: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        autoUpload: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-component-props': {
                defaultChecked: true,
            },
        },
    },
};
exports.Upload.Dragger = exports.Upload;
