"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberPicker = void 0;
exports.NumberPicker = {
    type: 'object',
    properties: {
        size: {
            type: 'string',
            enum: ['small', 'medium', 'large', null],
            'x-decorator': 'FormItem',
            'x-component': 'Select',
            'x-component-props': {
                defaultValue: 'medium',
            },
        },
        type: {
            type: 'string',
            enum: ['normal', 'inline'],
            'x-decorator': 'FormItem',
            'x-component': 'Radio.Group',
            'x-component-props': {
                defaultValue: 'normal',
                optionType: 'button',
            },
        },
        step: {
            type: 'number',
            'x-decorator': 'FormItem',
            'x-component': 'NumberPicker',
        },
        precision: {
            type: 'number',
            'x-decorator': 'FormItem',
            'x-component': 'NumberPicker',
            'x-component-props': {
                min: 0,
                precision: 0,
            },
        },
        autoFocus: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        max: {
            type: 'number',
            'x-decorator': 'FormItem',
            'x-component': 'NumberPicker',
        },
        min: {
            type: 'number',
            'x-decorator': 'FormItem',
            'x-component': 'NumberPicker',
        },
        innerAfter: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
        device: {
            type: 'string',
            enum: ['desktop', 'phone', 'tablet'],
            'x-decorator': 'FormItem',
            'x-component': 'Radio.Group',
            'x-component-props': {
                defaultValue: 'desktop',
                optionType: 'button',
            },
        },
        hasTrigger: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-component-props': {
                defaultValue: false,
            },
        },
        alwaysShowTrigger: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-reactions': {
                dependencies: ['.hasTrigger'],
                fulfill: {
                    state: {
                        visible: '{{$deps[0]}}',
                    },
                },
            },
        },
    },
};
