"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Space = void 0;
exports.Space = {
    'zh-CN': {
        title: '弹性间距',
        settings: {
            'x-component-props': {
                align: {
                    title: '对齐',
                    dataSource: ['起始', '结尾', '中间', '基线'],
                },
                split: '分割内容',
                wrap: '自动换行',
            },
        },
    },
    'en-US': {
        title: 'Space',
        settings: {
            'x-component-props': {
                align: {
                    title: 'Alignment',
                    dataSource: ['Start', 'End', 'Center', 'Baseline'],
                },
                split: 'Split',
                wrap: 'Auto Wrap',
            },
        },
    },
};
